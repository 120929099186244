import styled from 'styled-components';
import { Button, TextField } from '@mui/material';

export const ContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  span {
    button {
      background: transparent;
      border: 0;
    }
  }
`;

export const ContainerValidateCartela = styled.div`
  display: flex;
  align-items: center;

  border: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin-top: 5%;
  background: ${(props) => props.$valid === 'success' && '#F1F1F1'};
  padding: 2rem;

  height: 130px;
  width: 600px;

  svg {
    cursor: default !important;
    margin-bottom: 2rem;
    height: 40px;
    width: 40px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    height: 220px;
    width: 300px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

export const ContentInputNumberCartela = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-right: ${(props) => (props.$space ? '1rem' : 0)} !important;

  p {
    color: gray;
    opacity: 0.5;
    letter-spacing: 3px;
    font-size: 0.8rem;
  }
`;

export const InputNumberCartela = styled(TextField)`
  label {
    display: flex;
    font-size: 1.5rem;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  input {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      margin-top: 0;
      font-size: 1.2rem;
    }
  }

  .css-bn8pyn-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
    color: #01c914 !important; //LABEL DO INPUT NO MOMENTO DO SUCCESS
  }

  .css-4xvtb9-MuiInputBase-root-MuiInput-root.Mui-disabled:before {
    border-bottom: 2px solid #01c914 !important; //BORDA DO INPUT NO MOMENTO DO SUCCESS
  }
`;

// BUTTON VALIDATE CARTELA
export const ButtonValidateCartela = styled(Button)`
  width: 180px;

  svg {
    color: #fff;
    margin: 0 !important;
  }
`;

