import styled from 'styled-components';
import Hamburger from 'hamburger-react';
import { ListItem } from '@mui/material';
import { ContentWelcome } from '../NavBar/NavBarStyles';
import { Link } from 'react-router-dom';

export const ContainerMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentMenu = styled.div`
  height: 100%;
  width: 18rem;

  ul {
    height: 100%;

    & > span:last-child {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
`;

export const MenuButton = styled(ListItem)`
  display: flex;
  gap: 0.4rem;

  transition: 0.4s;

  span {
    font-weight: 600 !important;
  }

  svg {
    height: 1.8rem;
    width: 1.8rem;
  }

  &:hover {
    color: var(--primary);
  }
`;

export const MenuHamburger = styled(Hamburger)``;

export const ContentInfoUser = styled(ContentWelcome)`
  border-radius: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  margin-bottom: 0.6rem;
  /* padding: 1rem; */

  b {
    font-size: 0.7rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

export const InternalLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  color: #000;
  text-decoration: none;

  transition: 0.4s;

  &:hover {
    color: var(--primary);
  }
`;
