import styled from 'styled-components';
import { ButtonActiveCartela } from '../ValidateCartela/ValidateCartelaStyle';

export const ContainerLogin = styled.main`
  display: grid;
  grid-template-columns: 10% 30% 10% 40% 10%;
  grid-template-rows: 20% 60% 20%;

  background-color: var(--primary);
  height: 100vh;

  @media screen and (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: 45% 10% 45%;
  }
`;

export const TitleScreen = styled.h1`
  grid-area: 1 / 2 / 1 / 5;
  align-self: flex-end;
  justify-self: center;

  color: #fff;
  font-size: 4rem;
  letter-spacing: 4px;

  @media screen and (max-width: 800px) {
    grid-area: 1 / 1;

    align-self: flex-start;
    font-size: 2rem;
    letter-spacing: 3px;
    margin-top: 4rem;
  }
`;

export const ContentLogo = styled.span`
  grid-area: 2 / 2;

  align-self: center;
  justify-self: center;

  img {
    width: 24rem;
  }

  @media screen and (max-width: 800px) {
    grid-area: 1 / 1;

    img {
      margin-top: 8rem;
      width: 20rem;
    }
  }
`;

export const VerticalDivider = styled.div`
  grid-area: 2 / 3;

  align-self: center;
  justify-self: flex-end;

  background-color: #fff;
  border-radius: 20px;
  height: 20rem;
  width: 2px;

  @media screen and (max-width: 800px) {
    grid-area: 2 / 1;

    justify-self: center;
    height: 2px;
    width: 18rem;
  }
`;

export const ContentForm = styled.form`
  display: grid;
  grid-area: 2 / 4;
  gap: 2rem;

  align-self: center;

  width: 100%;

  p {
    font-size: 0.9rem;
    color: #fff;
    margin: 0 auto;
  }

  input {
    border: 2px solid var(--primaryLight);
    border-radius: 10px;
    font-size: 1.2rem;
    height: 3.2rem;
    margin: 0 auto;
    padding: 1rem;
    width: 60%;
  }

  @media screen and (max-width: 800px) {
    grid-area: 3 / 1;
    gap: 1.5rem;
    align-self: center;

    p {
      font-size: 0.8rem;
      max-width: 60%;
      text-align: center;
    }

    input {
      font-size: 1rem;
    }
  }
`;

export const ButtonLogin = styled(ButtonActiveCartela)`
  background-color: var(--primaryLight) !important;
  font-size: 1rem !important;
  letter-spacing: 1px !important;
  margin: 0 auto !important;
  height: 3rem;
  width: 60%;
`;

export const ResendCodeButton = styled.button`
  background: transparent;
  border: 0;
  color: #fff;
  text-decoration: underline;
`;

export const ContainerButtonResend = styled.span`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const ButtonResend = styled(ButtonActiveCartela)`
  background-color: #fff !important;
  font-size: 0.8rem !important;
  height: 2rem;
  letter-spacing: 1px !important;
  margin: 0 !important;
  width: 20%;

  &:hover {
    filter: brightness(0.9);
    font-weight: 700;
    transition: 0.4s;
  }

  svg {
    color: #1565b4;
    z-index: 99999;
  }
`;
