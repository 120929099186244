import styled from 'styled-components';
import { ButtonLogin } from '../Login/LoginStyle';
import { FaSearch } from 'react-icons/fa'; // Importe o ícone da lupa

export const ContainerSelectPdv = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  background-color: var(--primary);
  height: 100vh;
`;

export const Title = styled.h1`
  align-self: flex-end;
  justify-self: center;

  color: #fff;
  font-size: 3.2rem;
  letter-spacing: 4px;

  @media screen and (max-width: 800px) {
    grid-area: 1 / 1;

    align-self: center;
    font-size: 1.8rem;
    letter-spacing: 3px;
    margin-top: 2rem;
  }
`;

export const ContentSelectEstablishment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 2px 10px rgba(0, 0, 0, 0.3) !important;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  height: 25rem;
  width: 28rem;
  padding: 1.6rem 1.6rem;
  overflow: auto;

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    background: transparent;
    border: 0;
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;
    padding: 0;

    transition: transform 0.5s;

    &:hover {
      transform: scale(1.3);
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f4f4f4; /* Cor do polegar */
    border-radius: 4px; /* Borda arredondada para o polegar */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Cor da faixa (trilha) */
    margin: 0.3rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 1rem;
    width: 25rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1rem;
    height: 20rem;
    width: 20rem;
  }
`;

export const ContentNoHaveEstablishment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18rem;
  text-align: center;
`;

export const ContainerButtons = styled.div`
  display: flex;

  align-self: flex-start;

  button {
    max-width: 40%;
  }
`;

export const NextButton = styled(ButtonLogin)`
  background-color: var(--primaryLight);
  align-self: flex-start;
  color: white !important;
  max-width: 60%;

  @media screen and (max-width: 500px) {
    height: 2.6rem;
  }
`;

export const ExitButton = styled(NextButton)`
  background-color: var(--secondary) !important;
  @media screen and (max-width: 500px) {
    height: 2.6rem;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const InputSearch = styled.input`
  background-color: #fff;
  border-radius: 8px;
  height: 3rem;
  border: 0;
  padding: 0 1.2rem;
  font-size: 1.2rem;
  transform: translateY(40%);
  width: 100%;

  @media screen and (max-width: 500px) {
    height: 2.8rem;
  }
`;

export const SearchIcon = styled(FaSearch)`
  color: #000;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(30%);
  opacity: 0.6;
  font-size: 1.6rem;

  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
    transform: translateY(50%);
  }
`;
