import { BrowserRouter } from 'react-router-dom';
import { CustomRoutes } from './js/routes/Routes';
import { GlobalStyle } from './globalStyles/styles/global';
import { ToastContainer } from 'react-toastify';
import { NavBar } from './js/components/NavBar/NavBar';
import { UserProvider } from './js/hooks/useUser';
import { AccreditedProvider } from './js/hooks/useAccredited';
// import { Footer } from './js/components/Footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <AccreditedProvider>
          <ToastContainer position="bottom-right" autoClose={4000} />
          <NavBar />
          <CustomRoutes />
          <GlobalStyle />
          {/* <Footer /> */}
        </AccreditedProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
