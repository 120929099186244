import styled from 'styled-components';

import {
  ButtonValidateCartela,
  InputNumberCartela,
} from '../../components/InputValidCartela/InputValidCartelaStyle';

//CONTAINER VALIDATE CARTELA
export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  h2 {
    transform: translateY(100%);
  }

  span {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      margin-top: 2rem;
      fill: var(--primary);
      opacity: 0.8;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 500px) {
    span {
      gap: 0.8rem;

      svg {
        margin-top: 1rem;
        height: 40px;
        width: 40px;
      }
    }
  }
`;

//CONTAINER FORM INFOS
export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  border: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  width: 800px;

  svg {
    margin: 0 !important;
  }

  span {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    width: 320px;
  }
`;

export const InputForm = styled(InputNumberCartela)``;

export const ButtonActiveCartela = styled(ButtonValidateCartela)`
  margin-bottom: 2rem !important;
`;

export const invalidCpf = styled.p`
  font-size: 0.8rem;
  color: red;
  font-weight: 600;
`;
