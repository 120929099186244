import { useState } from 'react';
import { useAccredited } from '../../hooks/useAccredited';
import { useUser } from '../../hooks/useUser';

// STYLES
import {
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItemText,
} from '@mui/material';
import {
  ContainerMenu,
  ContentInfoUser,
  ContentMenu,
  InternalLink,
  MenuButton,
  MenuHamburger,
} from './MenuListStyles';
import { RxExit } from 'react-icons/rx';
import { GoArrowSwitch } from 'react-icons/go';

//COMPONENTS
import { ModalConfirmLogout } from '../Modals/ModalConfirmLogout/ModalConfirmLogout';

//OTHERS

export const MenuList = () => {
  const { userName } = useUser();
  const { accredited, setAccredited, pdv, setPdv } = useAccredited();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const nameLocal = sessionStorage.getItem('name');
  const open = Boolean(anchorEl);

  const toggleMenu = (isOpen) => (event) => {
    setOpenMenu(isOpen);

    setAnchorEl(isOpen === false ? null : event.currentTarget);
  };

  const handleOpenModalLogout = () => {
    setOpenLogoutModal(true);
  };

  const handleCloseModalLogout = () => {
    setOpenLogoutModal(false);
  };

  // LIMPA AS INFORMAÇÕES GUARDADAS DE CREDENCIADO E PDV AO CLICAR EM TROCAR PONTO DE VENDA
  const clearInfosSelectAccredited = () => {
    setAccredited({ accreditedId: '', accreditedName: '' });
    setPdv({ pdvId: '', pdvName: '' });
  };

  return (
    <ContainerMenu>
      <ModalConfirmLogout
        isOpen={openLogoutModal}
        onRequestClose={handleCloseModalLogout}
      />
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={toggleMenu(true)}
      >
        <MenuHamburger
          color={'white'}
          toggled={open}
          toggle={() => setAnchorEl(null)}
        />
      </Button>
      <Drawer anchor="right" open={openMenu} onClose={toggleMenu(false)}>
        <ContentMenu
          role="presentation"
          onClick={toggleMenu(false)}
          onKeyDown={toggleMenu(false)}
        >
          <List>
            <Hidden mdUp>
              <ContentInfoUser>
                <p>
                  <b>OPERADOR: </b>{' '}
                  {userName !== ''
                    ? userName?.toUpperCase()
                    : nameLocal?.toUpperCase()}
                </p>
                <p>
                  <b>CREDENCIADO:</b> {accredited.accreditedName?.toUpperCase()}
                </p>
                <p>
                  <b>PDV:</b> {pdv.pdvName?.toUpperCase()}
                </p>
              </ContentInfoUser>
            </Hidden>
            <Hidden mdUp>
              <Divider />
            </Hidden>
            <InternalLink
              to={'/selecionar-pdv'}
              onClick={clearInfosSelectAccredited}
            >
              <MenuButton button>
                <GoArrowSwitch />
                <ListItemText primary="Trocar ponto de venda" />
              </MenuButton>
            </InternalLink>
            <Divider />

            <span>
              <Divider />
              <MenuButton button onClick={handleOpenModalLogout}>
                <RxExit /> <ListItemText primary="Sair" />
              </MenuButton>
            </span>
          </List>
        </ContentMenu>
      </Drawer>
    </ContainerMenu>
  );
};
