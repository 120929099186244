import { Route, Routes } from 'react-router-dom';

// STYLES
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import { Login } from '../pages/Login/Login';
import { NoLoggedPage } from '../pages/NoLoggedPage/NoLoggedPage';
import { ValidateCartela } from '../pages/ValidateCartela/ValidateCartela';
import { SelectPdv } from '../pages/SelectPdv/SelectPdv';

export function CustomRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ValidateCartela />} />
        <Route path="/noAuthentication" element={<NoLoggedPage />} />
        <Route path="/selecionar-pdv" element={<SelectPdv />} />
        <Route path="/login" exact element={<Login />} />
      </Routes>
    </>
  );
}
