import axios from 'axios';
import { authTokenWithSC } from './getSecurityCodeWithSC';
import cfac22 from '../../../cfac22/cfac22';

//BUSCA USUÁRIO PELO CPF
export function validateSMS(uId, code, phoneNumber, transactionId) {
  return new Promise((resolve, reject) => {
    authTokenWithSC(uId)
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_NODE') + '/sms/validateSMS',
            {
              code,
              cellphone: phoneNumber,
              transactionId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (result) => {
            // console.log('Result VALIDATESMS ==> ', result.data);
            resolve(result.data);
          })
          .catch((error) => {
            // console.log('ERROR VALIDATESMS ==> ', error.response.data.error);
            reject(error.response.data.error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_GET_CHANNEL_TOKEN_GET_USER_INFO ==> ', error);
        reject(error);
      });
  });
}
