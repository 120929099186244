import { QrReader } from 'react-qr-reader';
import { ModalContainer } from '../GeneralStylesModal';
import { useEffect } from 'react';

export const ModalScan = ({ isOpen, onRequestClose, qrInfo, setQrInfo }) => {
  useEffect(() => {
    if (qrInfo !== 'No result') {
      onRequestClose();
    }
    // eslint-disable-next-line
  }, [qrInfo]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-scan"
    >
      <>
        <QrReader
          constraints={{ facingMode: 'environment' }}
          onResult={(result) => {
            if (!!result) {
              setQrInfo(result?.text);
            }
          }}
          style={{ width: '100%' }}
        />
      </>
    </ModalContainer>
  );
};

