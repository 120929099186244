import { useUser } from '../../../hooks/useUser';
import { ModalContainer } from '../GeneralStylesModal';
import {
  ButtonCloseModal,
  ButtonLogout,
  ContainerModalLogout,
  ContentButtons,
  Text,
} from './ModalConfirmLogoutStyle';
import closeImg from '../../../../globalStyles/assets/imgs/close.svg';

export const ModalConfirmLogout = ({ isOpen, onRequestClose }) => {
  const { logout } = useUser();

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ButtonCloseModal onClick={onRequestClose} type="button">
        <img src={closeImg} alt="Fechar modal" />
      </ButtonCloseModal>
      <ContainerModalLogout>
        <Text>Tem certeza de que deseja sair ?</Text>
        <ContentButtons>
          <ButtonLogout onClick={onRequestClose} variant="outlined">
            Cancelar
          </ButtonLogout>
          <ButtonLogout onClick={logout} variant="contained">
            Confirmar
          </ButtonLogout>
        </ContentButtons>
      </ContainerModalLogout>
    </ModalContainer>
  );
};
