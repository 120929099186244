import styled from 'styled-components';

export const NologgedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--primary);
`;

export const ContentInfo = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  background-color: var(--primary);
  height: 600px;
  width: 600px;

  svg {
    width: 15rem;
  }

  h2 {
    font-size: 2.2rem;
    color: #fff;
    text-align: center;
  }

  a {
    color: #ffc10d;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: #000;
    }
  }
`;
