import { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { useAccredited } from '../../hooks/useAccredited';

// STYLES
import {
  ContainerButtons,
  ContentWelcome,
  Logo,
  NavbarContainer,
} from './NavBarStyles';
import { Hidden } from '@mui/material';
import logo from '../../../globalStyles/assets/imgs/logo-sorte-saude.svg';

// COMPONENTS
import { MenuList } from '../MenuList/MenuList';

export const NavBar = () => {
  const nameLocal = sessionStorage.getItem('name');
  const { accredited, pdv } = useAccredited();
  const { userName } = useUser();
  const { pathname } = document.location;

  const [visibleBar, setVisibleBar] = useState(false);

  // DEFINE ONDE O A BARRA NÃO VAI APARECER
  useEffect(() => {
    if (
      pathname === '/login' ||
      pathname === '/noAuthentication' ||
      pathname === '/selecionar-pdv'
    ) {
      setVisibleBar(false);
    } else {
      setVisibleBar(true);
    }
  }, [pathname]);

  return (
    <>
      {!visibleBar ? null : (
        <NavbarContainer>
          <Logo src={logo} alt="Logomarca sorte e saude" />
          <ContainerButtons>
            <Hidden mdDown>
              <ContentWelcome>
                <p>
                  <b>OPERADOR: </b>{' '}
                  {userName !== ''
                    ? userName?.toUpperCase()
                    : nameLocal?.toUpperCase()}
                </p>
                <p>
                  <b>CREDENCIADO:</b> {accredited.accreditedName?.toUpperCase()}
                </p>
                <p>
                  <b>PDV:</b> {pdv.pdvName?.toUpperCase()}
                </p>
              </ContentWelcome>
            </Hidden>
            <MenuList />
          </ContainerButtons>
        </NavbarContainer>
      )}
    </>
  );
};
