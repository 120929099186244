import { useState, createContext, useContext } from 'react';

const AccreditedContext = createContext({});

export function AccreditedProvider({ children }) {
  const [accredited, setAccredited] = useState({
    accreditedId: '',
    accreditedName: '',
  });
  const [pdv, setPdv] = useState({
    pdvId: '',
    pdvName: '',
  });

  return (
    <AccreditedContext.Provider
      value={{
        accredited,
        setAccredited,
        pdv,
        setPdv,
      }}
    >
      {children}
    </AccreditedContext.Provider>
  );
}

export const useAccredited = () => {
  const context = useContext(AccreditedContext);

  return context;
};
