import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// STYLES
import { ContentInfo, NologgedContainer } from './NoLoggedPageStyle';
import { ReactComponent as NoAccess } from '../../../globalStyles/assets/imgs/noAccess.svg';

export function NoLoggedPage() {
  const navigate = useNavigate();
  const isLogged = JSON.parse(sessionStorage.getItem('redirect'));

  // SE O USUARIO ESTIVER LOGADO NÃO POSSUI ACESSO A ESTA PÁGINA;
  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [isLogged]);

  return (
    <NologgedContainer>
      <ContentInfo>
        <NoAccess />
        <h2>Você não possui permissão para acessar essa página.</h2>
        <Link to="/login">FAZER LOGIN</Link>
      </ContentInfo>
    </NologgedContainer>
  );
}
