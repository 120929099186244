import { useState, useRef, useEffect } from 'react';
import { useAccredited } from '../../hooks/useAccredited';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// STYLES
import {
  ButtonActiveCartela,
  ContainerForm,
  ContainerPage,
  InputForm,
} from './ValidateCartelaStyle';
import { CircularProgress } from '@mui/material';

// COMPONENTS
import { ModalScan } from '../../components/Modals/ModalScan/ModalScan';
import { InputValidCartela } from '../../components/InputValidCartela/InputValidCartela';

// APIS
import { activateCartelaApi } from '../../services/Apis/ApisSorteSaude/activateCartelaApi';
import { activateCartelaApiV2 } from '../../services/Apis/ApisSorteSaude/activateCartelaApiV2';
import { validCards } from '../../services/Apis/ApisSorteSaude/validCards';
import { validCardsV2 } from '../../services/Apis/ApisSorteSaude/validCardsV2';

import { getUserBrasilCapWithDocument } from '../../services/Apis/ApisSorteSaude/getUserBrasilCap';

// OTHERS
import { handleChangeMask } from '../../utils/masks';
import { cellphoneTreatment, validateCpf } from '../../utils/helpers';

export function ValidateCartela() {
  const { accredited, pdv } = useAccredited();
  const uId = JSON.parse(sessionStorage.getItem('user'))?.uId;
  const isLogged = JSON.parse(sessionStorage.getItem('redirect'));
  const cpfInputRef = useRef(null);
  const navigate = useNavigate();

  const [accreditedIdPullValidCards, setAccreditedIdPullValidCards] = useState('');
  const [cartelaPart1, setCartelaPart1] = useState('');
  const [cartelaPart2, setCartelaPart2] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [cartelaIsValid, setCartelaIsValid] = useState(null); // RETURN 'success' or 'error';
  const [openScan, setOpenScan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [searchCpfLoading, setSearchCpfLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    cpf: '',
    name: '',
    cellphone: '',
  });

  // SE NO INPUT 2 DA CARTELA TIVER APENAS _ 'UNDERSCORE', ELE É REMOVIDO;
  useEffect(() => {
    if (cartelaPart2 === '_') {
      setCartelaPart2('');
    }
  }, [cartelaPart1, cartelaPart2]);

  // PEGA A LEITURA DO QRCODE E QUEBRA A CARTELA PARA SER MOSTRADO NOS 2 INPUTS
  useEffect(() => {
    if (qrCode !== '') {
      const part1 = qrCode.substring(0, 13);
      const part2 = qrCode.substring(14);

      setCartelaPart1(part1);
      setCartelaPart2(part2);
    }
  }, [qrCode]);

  const handleCartela = (e, input) => {
    switch (input) {
      //COLOCA A MASCARA NO INPUT1 DA CARTELA
      case 'cartelaPart1':
        const newCartelaPart1 = handleChangeMask(
          'cartelaPart1',
          e.target.value
        ).toLocaleUpperCase();
        setCartelaPart1(newCartelaPart1);
        break;
      case 'cartelaPart2':
        // PERMITE APENAS NUMBERS
        let value = e.target.value.replace(/[^\d_]/g, '').toLocaleUpperCase();

        //LIDA COM O _ 'UNDERSCORE' DO INPUT2
        value = value.replace(/_/g, '');
        value = value.slice(0, -1) + '_' + value.slice(-1);

        setCartelaPart2(value);
        break;
      default:
        console.log('unknown');
    }
  };

  // APLICA MASCARA A CAMPOS NECESSÁRIOS
  const handleForm = (e, type) => {
    switch (type) {
      case 'cpf':
        setCustomerInfo({
          ...customerInfo,
          cpf: handleChangeMask('cpf', e.target.value),
        });
        break;
      case 'cellphone':
        setCustomerInfo({
          ...customerInfo,
          cellphone: handleChangeMask('cellphone', e.target.value),
        });
        break;
      default:
        console.log('Unknown');
    }
  };
  // VERIFICA SE A CARTELA É VALIDA
  const validateCartela = async () => {
    setLoading(true);
    const cartela = cartelaPart1 + '_' + cartelaPart2;
    try {
      if (
        accredited.accreditedId === '50266926000100' ||
        accredited.accreditedId === '41708602844'
      ) {
        const accreditedIdApi = await validCardsV2(uId, accredited.accreditedId, cartela);
        setAccreditedIdPullValidCards(accreditedIdApi.pdvId);
        setCartelaIsValid('success'); //'error' ou 'success'
      } else {
        await validCards(uId, accredited.accreditedId, cartela);
        setCartelaIsValid('success'); //'error' ou 'success'
      }
    } catch (error) {
      toast.error(error.error);
      setCartelaIsValid('error'); //'error' ou 'success'
    } finally {
      setLoading(false);
    }
  };

  const handleCloseScan = () => {
    setOpenScan(false);
  };

  const handleOpenScan = () => {
    setOpenScan(true);
  };

  const activeCartela = async () => {
    try {
      setLoadingActive(true);

      // REMOVE OS '.' E '-' DO CPF E TRANSFORMA '-' EM '_' DA CARTELA
      const cartela = cartelaPart1 + '_' + cartelaPart2;
      const cpfTreatment = customerInfo.cpf.replace(/[-.]/g, '');
      const cartelaTreatment = cartela.replace(/-/g, '_');

      const activeCartelaInfos = {
        pdvId:
          accredited.accreditedId === '50266926000100' || accredited.accreditedId === '41708602844'
            ? accreditedIdPullValidCards
            : accredited.accreditedId,
        establishmentId: pdv.pdvId,
        cardId: cartelaTreatment,
        customerCPF: cpfTreatment,
        customerName: customerInfo.name,
        customerCellphone: cellphoneTreatment(customerInfo.cellphone),
      };

      if (activeCartelaInfos.customerCellphone.length !== 14) {
        return toast.warning('Número de celular incompleto, verifique e tente novamente.');
      }

      if (customerInfo.cpf.length < 14) {
        return toast.error('O CPF deve conter 11 dígitos.');
      } else if (!validateCpf(cpfTreatment)) {
        return toast.error('Digite um CPF válido.');
      } else if (customerInfo.name.length <= 2) {
        return toast.error('Digite um nome válido com mais de 2 caracteres.');
      } else {
        if (
          accredited.accreditedId === '50266926000100' ||
          accredited.accreditedId === '41708602844'
        ) {
          await activateCartelaApiV2(uId, activeCartelaInfos);
          setCartelaIsValid(null);
          setQrCode('');
          setCartelaPart1('');
          setCartelaPart2('');
          setCustomerInfo({
            cpf: '',
            name: '',
            cellphone: '',
          });
          toast.success('Cartela ativada com sucesso.');
        } else {
          await activateCartelaApi(uId, activeCartelaInfos);
          setCartelaIsValid(null);
          setQrCode('');
          setCartelaPart1('');
          setCartelaPart2('');
          setCustomerInfo({
            cpf: '',
            name: '',
            cellphone: '',
          });
          toast.success('Cartela ativada com sucesso.');
        }
      }
    } catch (error) {
      console.log('API MESSAGE ERROR ==>', error.error);
      toast.error(error.error);
    } finally {
      setLoadingActive(false);
    }
  };

  // VERIFICA SE O USER EXISTE, E ATUALIZA AS INFOS DO FORM
  useEffect(() => {
    if (customerInfo.cpf.length === 14) {
      setSearchCpfLoading(true);
      const sanitizeCpf = customerInfo.cpf.replace(/[^a-zA-Z0-9+\s]/g, '').replace(/\s/g, '');

      getUserBrasilCapWithDocument(sanitizeCpf)
        .then((user) => {
          if (user === false) {
            setCustomerInfo((prevCustomerInfo) => ({
              ...prevCustomerInfo,
              name: '',
              cellphone: '',
            }));
          } else {
            const partner = user.partnerList.filter((x) => x.partnerName === 'BrasilCap')[0];

            const cellphone = partner.contactList
              ?.map((phone) => phone.type === 'cellPhone' && phone.value)
              .filter(Boolean)[0];

            setCustomerInfo((prevCustomerInfo) => ({
              ...prevCustomerInfo,
              name: partner.name ? partner.name : '',
              cellphone: cellphone ? handleChangeMask('cellphone', cellphone) : '',
            }));

            setSearchCpfLoading(false);
          }
        })
        .catch((error) => {
          toast.warning(
            'Não foi possivel encontrar nenhum usuario. Preencha as informações do formulário.'
          );
          console.log(error);
        })
        .finally(() => {
          setSearchCpfLoading(false);
        });
    }
  }, [customerInfo.cpf]);

  // SE A CARTELA FOR VÁLIDA DA UM FOCUS NO CPF
  useEffect(() => {
    if (cartelaIsValid === 'success') {
      cpfInputRef.current && cpfInputRef.current.focus();
    }
  }, [cartelaIsValid]);

  // SE NÃO ESTIVER LOGADO REDIRECIONA PARA PÁGINA DE LOGIN SE NÃO TIVER SETADO ESTAB E PDV REDIRECIONA PARA SELEÇÃO
  useEffect(() => {
    if (!isLogged) {
      navigate('/login');
    } else {
      if (
        accredited.accreditedId === '' ||
        accredited.accreditedName === '' ||
        pdv.pdvId === '' ||
        pdv.pdvName === ''
      ) {
        navigate('/selecionar-pdv');
      }
    }
    // eslint-disable-next-line
  }, [isLogged, accredited, pdv]);

  return (
    <ContainerPage>
      <ModalScan
        isOpen={openScan}
        onRequestClose={handleCloseScan}
        qrInfo={qrCode}
        setQrInfo={setQrCode}
      />
      <span>
        <InputValidCartela
          cartelaPart1={cartelaPart1}
          cartelaPart2={cartelaPart2}
          handleCartela={handleCartela}
          handleOpenScan={handleOpenScan}
          isValid={cartelaIsValid}
          loading={loading}
          validateCartela={validateCartela}
        />
      </span>
      {cartelaIsValid === 'success' ? (
        <>
          <ContainerForm>
            {searchCpfLoading ? (
              <CircularProgress size={26} thickness={5} style={{ color: '#1565B4' }} />
            ) : (
              <InputForm
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputRef={cpfInputRef}
                label="CPF"
                onChange={(e) => handleForm(e, 'cpf')}
                placeholder="123.456.789-10"
                required
                value={customerInfo.cpf}
                variant="standard"
              />
            )}
            <InputForm
              disabled={customerInfo.cpf.length < 14}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Nome"
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  name: e.target.value
                    .replace(/[0-9]/g, '')
                    .replace(/[!@#$%&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ''),
                })
              }
              placeholder="John Doe"
              required
              value={customerInfo.name}
              variant="standard"
            />
            <InputForm
              disabled={customerInfo.cpf.length < 14}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Número de celular"
              onChange={(e) => handleForm(e, 'cellphone')}
              placeholder="(XX) XXXXX-XXXX"
              required
              value={customerInfo.cellphone}
              variant="standard"
            />
          </ContainerForm>
          <ButtonActiveCartela
            disabled={
              customerInfo.cpf === '' ||
              customerInfo.name === '' ||
              customerInfo.cellphone.length !== 19 ||
              loadingActive
            }
            label="ATIVAR CARTELA"
            onClick={activeCartela}
            size="large"
            variant="contained"
          >
            {loadingActive ? <CircularProgress size={26} thickness={5} /> : 'ATIVAR CARTELA'}
          </ButtonActiveCartela>
        </>
      ) : null}
    </ContainerPage>
  );
}

