import { useRef } from 'react';
// STYLES
import { BsQrCodeScan } from 'react-icons/bs';
import { MdErrorOutline } from 'react-icons/md';
import { ReactComponent as IconCheck } from '../../../globalStyles/assets/imgs/checkIcon.svg';
import {
  ButtonValidateCartela,
  ContainerSection,
  ContainerValidateCartela,
  ContentInputNumberCartela,
  InputNumberCartela,
} from './InputValidCartelaStyle';

// OTHERS
import { CircularProgress, /* Hidden, */ Tooltip } from '@mui/material';

export const InputValidCartela = ({
  cartelaPart1,
  cartelaPart2,
  handleCartela,
  handleOpenScan,
  isValid,
  loading,
  validateCartela,
}) => {
  const firstPartCartelaRef = useRef(null);
  const secondPartCartelaRef = useRef(null);

  // Função para remover o caractere de underscore quando o usuário pressiona a tecla "backspace"
  const handleBackspace = (e) => {
    if (e.key === 'Backspace' && e.target.value.includes('_')) {
      e.preventDefault(); // Evita a ação padrão de remover o último caractere
      const cursorPosition = e.target.selectionStart; // Obtém a posição do cursor
      const newValue =
        e.target.value.slice(0, cursorPosition - 1) + e.target.value.slice(cursorPosition); // Remove o caractere de sublinhado
      e.target.value = newValue; // Atualiza o valor do campo
      handleCartela({ target: { value: newValue } }, 'cartelaPart1'); // Atualiza o estado com o novo valor
    }
  };

  // Função que retorna o foco ao input 1 ao apagar todo conteúdo do input 2
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value === '' && firstPartCartelaRef.current) {
      e.preventDefault(); // Evita a ação padrão de remover o último caractere
      firstPartCartelaRef.current.focus(); // Volta o foco para o primeiro input
    }
  };

  // Define o foco no segundo campo quando o primeiro estiver preenchido
  if (cartelaPart1.length === 13 && secondPartCartelaRef.current) {
    secondPartCartelaRef.current.focus();
  }

  return (
    <ContainerSection>
      <span>
        <ContainerValidateCartela $valid={isValid === 'success' ? 1 : 0}>
          <ContentInputNumberCartela $space>
            <InputNumberCartela
              color={
                isValid === 'success'
                  ? 'success'
                  : isValid === 'error'
                  ? 'error'
                  : isValid === null && null
              }
              disabled={isValid === 'success'}
              focused
              fullWidth
              label="Número da cartela"
              onChange={(e) => handleCartela(e, 'cartelaPart1')}
              onKeyDown={handleBackspace}
              placeholder="AA_XXXX_XXXXX"
              inputRef={firstPartCartelaRef}
              required
              value={cartelaPart1}
              variant="standard"
            />
            <p>Serie - Caixa - Lote</p>
          </ContentInputNumberCartela>
          <ContentInputNumberCartela>
            <InputNumberCartela
              color={
                isValid === 'success'
                  ? 'success'
                  : isValid === 'error'
                  ? 'error'
                  : isValid === null && null
              }
              disabled={isValid === 'success'}
              focused
              fullWidth
              label=" "
              inputProps={{ maxLength: 18 }}
              onChange={(e) => handleCartela(e, 'cartelaPart2')}
              onKeyDown={handleKeyDown}
              placeholder="XXXXXXXXXXXX_X"
              inputRef={secondPartCartelaRef}
              value={cartelaPart2}
              variant="standard"
            />
            <p>Cartela - Digito verificador</p>
          </ContentInputNumberCartela>
          {isValid === 'success' ? (
            <IconCheck />
          ) : isValid === 'error' ? (
            <MdErrorOutline style={{ fill: 'red' }} />
          ) : (
            isValid === null && null
          )}
        </ContainerValidateCartela>
        {/* <Hidden smUp> */}
        <Tooltip title="Ler QR Code">
          <span>
            <BsQrCodeScan onClick={() => handleOpenScan()} size="55px" />
          </span>
        </Tooltip>
        {/* </Hidden> */}
      </span>

      <ButtonValidateCartela
        disabled={
          cartelaPart1.length < 13 || cartelaPart2.length < 8 || loading || isValid === 'success'
        }
        label="Validar Cartela"
        onClick={validateCartela}
        size="large"
        variant="contained"
      >
        {loading ? <CircularProgress size={26} thickness={5} /> : 'VALIDAR CARTELA'}
      </ButtonValidateCartela>
    </ContainerSection>
  );
};

