import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// APIS
import { checkUserAccredited } from '../../services/Apis/ApisSorteSaude/checkUserAccredited';

// COMPONENTS
import { Step1 } from '../../components/Steps/Step1';
import { Step2 } from '../../components/Steps/Step2';

export function SelectPdv() {
  const { uId } = JSON.parse(sessionStorage.getItem('user'));
  const isLogged = JSON.parse(sessionStorage.getItem('redirect'));
  const navigate = useNavigate();

  const [infoPdv, setInfoPdv] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  // PEGA AS INFORMAÇÔES DOS ESTABELECIMENTOS DO USER
  useEffect(() => {
    setLoading(true);

    checkUserAccredited(uId)
      .then((res) => {
        const pdv = [];

        res.userEst.map((item) => {
          const accreditedInfo = {
            accreditedName: item.pdvName,
            accreditedId: item.pdvId,
            establishments: Object.values(item)[2],
          };
          return pdv.push(accreditedInfo);
        });

        res.otherEst.map((item) => {
          const accreditedInfo = {
            accreditedName: item.pdvName,
            accreditedId: item.pdvId,
            establishments: Object.values(item)[2],
          };
          return pdv.push(accreditedInfo);
        });

        setInfoPdv(pdv);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uId]);

  // SE O USUARIO NÃO ESTIVER LOGADO NÃO POSSUI ACESSO A ESTA PÁGINA;
  useEffect(() => {
    if (!isLogged) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [isLogged]);

  const next = (direction) => {
    if (direction === 'next') {
      setStep(2);
    } else if (direction === 'prev') {
      setStep(1);
    } else {
      navigate('/');
    }
  };

  return (
    <>
      {step === 1 ? (
        <Step1 loading={loading} next={next} infos={infoPdv} />
      ) : (
        <Step2 loading={loading} next={next} infos={infoPdv} />
      )}
    </>
  );
}
