import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../../../cfac22/cfac22';

export function validCardsV2(uId, pdvId, cardId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_NODE') + `/sorteSaude/cards/v2/validCards`,
            {
              pdvId,
              cardId,
            },
            { headers: { authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log('RESULT ==>', result.data);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR ==>', error);
            reject(error.response.data); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
