import { useState, createContext, useEffect, useContext } from 'react';
import { authCode } from '../services/Apis/authCode';
import { validateUserSorteSaude } from '../services/Apis/ApisSorteSaude/validateUserSorteSaude';
import { toast } from 'react-toastify';
import { decrypt } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';
import cfac22 from '../../cfac22/cfac22';

const UserContext = createContext({});

export function UserProvider({ children }) {
  const navigate = useNavigate();
  const sendType = { email: false, sms: true, whatsapp: false };
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [transactionId, setTransactionId] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const logged = sessionStorage.getItem('redirect');
    const recoveredUser = sessionStorage.getItem('user');

    if (recoveredUser && logged) {
      setUser(JSON.parse(recoveredUser));
      setRedirect(JSON.parse(logged));
    }
    setLoading(false);
  }, []);

  const resendCode = async (phone, sendType) => {
    setLoadingResendCode(true);
    try {
      const sendCode = await authCode(phone, sendType);
      if (sendCode.success) {
        sessionStorage.setItem('transactionId', JSON.stringify(sendCode.transactionId));
        toast.success('Código reenviado com sucesso. Aguarde alguns instantes.');
      }
    } catch (error) {
      console.error('Erro ao reenviar código:', error);
      toast.error('Erro ao reenviar código, tente novamente mais tarde.');
    } finally {
      setLoadingResendCode(false);
    }
  };

  const login = async (cpf) => {
    try {
      // RETORNA SE O USER É UM USER VÁLIDO NO SORTE E SAUDE.
      const isValidUser = await validateUserSorteSaude(cpf);
      // DESCRIPTOGRAFA AS INFOS;
      const decryptUserInfo = JSON.parse(decrypt(isValidUser, cfac22('CRYPTO_KEY')));

      const { uId } = decryptUserInfo;
      const { cellPhone } = decryptUserInfo;
      if (uId !== null && decryptUserInfo.isEnabled) {
        const sendCode = await authCode(cellPhone, sendType);
        const userObj = { uId, cellPhone };
        sessionStorage.setItem('user', JSON.stringify(userObj));
        sessionStorage.setItem('transactionId', JSON.stringify(sendCode.transactionId));

        setUserName(decryptUserInfo.name);
        setUser(decryptUserInfo.isEnabled);
        sessionStorage.setItem('name', decryptUserInfo.name);
        return cellPhone;
      } else {
        toast.error('Usuário não autorizado.');
      }
    } catch (error) {
      toast.error(error);
      console.log('Error ==>', error);
    }
  };

  const logout = () => {
    setUser(false);
    sessionStorage.clear();
    navigate('/login');
  };

  return (
    <UserContext.Provider
      value={{
        authenticated: !!user && !!redirect,
        login,
        logout,
        resendCode,
        loading,
        loadingResendCode,
        userName,
        transactionId,
        setTransactionId,
        redirect,
        setRedirect,
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  const context = useContext(UserContext);

  return context;
};
