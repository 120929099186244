import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../../../cfac22/cfac22';

export function checkUserAccredited(uId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .get(cfac22('API_HOST_NODE') + `/sorteSaude/cards/checkUserAccredited`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((result) => {
            // console.log('RESULT  ==>', result.data.response);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR  ==>', error);
            reject(error.response.data.error); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
