import styled from 'styled-components';

export const NavbarContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--primary);
  color: #fff;
  padding: 0.8rem 2rem 0.8rem 5rem;

  @media screen and (max-width: 800px) {
    padding: 0.8rem 0.5rem;
  }
`;

export const Logo = styled.img`
  width: 10rem;

  @media screen and (max-width: 500px) {
    width: 8rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 500px) {
    gap: 0.4rem;
  }
`;

export const ContentButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 1px;
  transition: 0.2s;

  &:hover {
    font-weight: 500;
  }

  @media screen and (max-width: 500px) {
    font-size: 0.7rem;
    margin-top: ${(props) => (props.$margintop ? '2px' : 0)};
  }
`;

export const ContentWelcome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  background: #fff;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  color: var(--secondary);
  font-weight: 700;
  margin-right: 0.6rem;
  padding: 0.6rem 0.8rem;

  b {
    color: var(--primary);
    font-size: 0.9rem;
  }

  span {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 1rem;
  }

  @media screen and (max-width: 500px) {
    margin: 0;

    b {
      font-size: 0.6rem;
    }

    p {
      font-size: 0.7rem;
    }
  }
`;
