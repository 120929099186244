import axios from 'axios';
import { authTokenWithSC } from '../getSecurityCodeWithSC';
import cfac22 from '../../../../cfac22/cfac22';

export function getUserBrasilCap(phone) {
  return new Promise((resolve, reject) => {
    authTokenWithSC()
      .then((token) => {
        axios
          .post(cfac22('API_HOST_NODE') + `/users/getUserBrasilCap/+55${phone}`, null, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((result) => {
            // console.log('RESULT USER BRASIL CAP ==>', result.data.response);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR USER BRASIL CAP ==>', error);
            reject(error.response); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}

export function getUserBrasilCapWithDocument(document) {
  return new Promise((resolve, reject) => {
    authTokenWithSC()
      .then((token) => {
        axios
          .post(cfac22('API_HOST_NODE') + `/users/getUserBrasilCap/${document}`, null, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((result) => {
            // console.log('RESULT USER BRASIL CAP ==>', result.data.response);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR USER BRASIL CAP ==>', error);
            reject(error.response.data.error); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
