import axios from 'axios';
import { validateRegistrationDate } from '../../utils/helpers';
import cfac22 from '../../../cfac22/cfac22';

export default async function authToken(uId) {
  return new Promise(async (resolve, reject) => {
    //PEGA O TOKEN DO LOCAL_STORAGE
    const token = JSON.parse(sessionStorage.getItem('triiboTokenPdvSS'));

    let auth = window.btoa(cfac22('username') + ':' + cfac22('password'));

    const config = {
      method: 'post',
      url: cfac22('API_HOST_NODE') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        uid: uId,
      },
    };

    //VERIFICA SE O TOKEN AINDA É VALIDO
    const valideDate = validateRegistrationDate(token?.date, 15);
    if (token === null || token === undefined || !valideDate || uId !== token.uId) {
      axios(config)
        .then(function (response) {
          sessionStorage.setItem(
            'triiboTokenPdvSS',
            JSON.stringify({
              token: response.data.token,
              date: Date.now(),
              uId,
            })
          );
          resolve(response.data.token);
        })
        .catch(function (error) {
          reject(error);
        });
    } else {
      resolve(token.token);
    }
  });
}
