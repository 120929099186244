import CryptoJS from 'crypto-js';
import md5 from '../utils/md5';

export function validateRegistrationDate(registrationDate) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;
    const date = Math.floor((mkTimeCurrent - mkTime) / 60 / 60);
    if (date > 20 || registrationDate === null) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
}

export function isMobile() {
  const { width } = window.screen;
  return width <= 720;
}

export function decrypt(msg, key) {
  if (!msg) return null;
  msg = decodeURIComponent(msg);
  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };
  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };
  let json = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(msg),
    },
    myEncrypt.pass,
    options
  );
  return json.toString(CryptoJS.enc.Utf8);
}

export function encrypt(msg, key) {
  if (!msg) return null;
  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };
  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };
  let json = CryptoJS.AES.encrypt(msg, myEncrypt.pass, options);
  return json.ciphertext.toString(CryptoJS.enc.Base64);
}

// VERIFICA SE O NUMERO DE TELEFONE TEM O PREFIXO +55
export function hasExactBrazilianPrefix(phoneNumber) {
  return /^\+55/.test(phoneNumber);
}

// REMOVE CARACTERES ESPECIAIS, DEIXA O NÚMERO DA MANEIRA QUE AS API RECEBE
export const cellphoneTreatment = (phone) => {
  // REMOVE CARACTERES ESPECIAIS EXCETO O '+' E REMOVE OS ESPAÇOS
  const phoneClear = phone.replace(/[^a-zA-Z0-9+\s]/g, '').replace(/\s/g, '');

  // VERIFICA SE O NÚMERO POSSUI O +55 SE NÃO HOUVER COLOCA O +55
  return hasExactBrazilianPrefix(phoneClear) ? phoneClear : '+55' + phoneClear;
};

// Função para validar CPF
export const validateCpf = (cpf) => {
  const cpfNumeros = cpf.replace(/[^\d]/g, '');
  if (cpfNumeros.length !== 11) {
    return false;
  }

  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpfNumeros.charAt(i)) * (10 - i);
  }
  let resto = soma % 11;
  let digitoVerificador1 = resto < 2 ? 0 : 11 - resto;

  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpfNumeros.charAt(i)) * (11 - i);
  }
  resto = soma % 11;
  let digitoVerificador2 = resto < 2 ? 0 : 11 - resto;

  if (
    parseInt(cpfNumeros.charAt(9)) === digitoVerificador1 &&
    parseInt(cpfNumeros.charAt(10)) === digitoVerificador2
  ) {
    return true;
  } else {
    return false;
  }
};
