const cfac22p =
  'ewogICJQUk39KRUNUX05BTUUiOiAiU29ydGUgU2HDumRlIC0gUERWIiwKCiAgInVzZXJuYW1lIjogImFkbWluU29ydGVzYXVkZSIsCiAgInBhc3N3b3JkIjogIm9zMjZPUHBGTlBraEcxa1EiLAoKICAiU0VDVVJJVFlDT0RFX0tFWSI6ICI3MTY3NDEyMzg4Nzk2OTc1MDY3OTI1NjExODMyNjMwMyIsCiAgIkNSWVBUT19LRVkiOiAiMTZWS1FZRU05Uk9VT0FOUTdFQkJDWFREOVRSWjBTMDgiLAogIAogICJGSVJFQkFTRV9BUElLRVkiOiAiQUl6YVN5REZUUVl5VWZvTi1acl80WVdLV0Q5MFJlSDZTanlkM3pNIiwKICAiRklSRUJBU0VfQVVUSERPTUFJTiI6ICJ0cmlpYm8uZmlyZWJhc2VhcHAuY29tIiwKICAiRklSRUJBU0VfUFJPSkVDVElEIjogInByb2plY3QtNDYxNjkyMDQ4MDI0NjI2NjQyOSIsCiAgIlNUT1JBR0VfVVJMIjogImh0dHBzOi8vZmlyZWJhc2VzdG9yYWdlLmdvb2dsZWFwaXMuY29tL3YwL2IvcHJvamVjdC00NjE2OTIwNDgwMjQ2MjY2NDI5LmFwcHNwb3QuY29tL28vIiwKICAKICAiQVBJX0hPU1RfTk9ERSI6ICJodHRwczovL2FwaS50cmlpYm8uY29tLmJyIgp9';
const cfac22h =
  'ewogICJQUk39KRUNUX05BTUUiOiAiU29ydGUgU2HDumRlIC0gUERWIiwKICAKICAidXNlcm5hbWUiOiAic29ydGVzYXVkZSIsCiAgInBhc3N3b3JkIjogIlRuUThQd1ZxYUNGd0tlN0wiLAoKICAiU0VDVVJJVFlDT0RFX0tFWSI6ICI1MzI2MzU3MTkxODUyMjg0ODYzOTAwMTcwNDg4MTIxOCIsCiAgIkNSWVBUT19LRVkiOiAiWDJaUE4wMUUwOEo0TVkxRzJQU1NURE9PUEE4N1RIN0ciLAogIAogICJGSVJFQkFTRV9BUElLRVkiOiAiQUl6YVN5RC1rNWF3VkxyREsxQjBETmRPZVN4YWI2b1VOTWZjQnFJIiwKICAiRklSRUJBU0VfQVVUSERPTUFJTiI6ICJ0cmlpYm8taG9tb2wuZmlyZWJhc2VhcHAuY29tIiwKICAiRklSRUJBU0VfUFJPSkVDVElEIjogInRyaWliby1ob21vbCIsCiAgIlNUT1JBR0VfVVJMIjogImh0dHBzOi8vZmlyZWJhc2VzdG9yYWdlLmdvb2dsZWFwaXMuY29tL3YwL2IvdHJpaWJvLWhvbW9sLmFwcHNwb3QuY29tL28vIiwKICAKICAiQVBJX0hPU1RfTk9ERSI6ICJodHRwczovL2FwaS1obWwtbm9kZS50cmlpYm8uY29tLmJyIgp9';

export default function cfac22(r) {
  const cfac = process.env.NODE_ENV === 'development' ? cfac22p : cfac22p;
  const cfac22 = JSON.parse(window.atob(cfac.substring(0, 10) + cfac.substring(11)));

  return cfac22[r];
}
