import axios from 'axios';
import { authTokenWithSC } from './getSecurityCodeWithSC';
import cfac22 from '../../../cfac22/cfac22';

export function authCode(cellPhone, sendType) {
  //OBTER INFORMAÇÕES DO USUÁRIO
  return new Promise((resolve, reject) => {
    authTokenWithSC()
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_NODE') + '/authCode',
            {
              cellphone: cellPhone,
              platform: 'SorteSaude-PDV',
              languageCode: 'pt_BR',
              sendType,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            if (result.data.success !== null) {
              resolve(result.data);
            } else {
              reject({ messageError: result.response.data.error });
              // console.log(result);
            }
          })
          .catch((error) => {
            console.log('ERROR AUTH SMS ===>', error.response.data.error);
            reject(error.response.data.error);
          });
      })
      .catch((error) => {
        console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
