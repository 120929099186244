import { mask } from 'remask';

export function handleChangeMask(type, value) {
  switch (type) {
    case 'cartelaPart1':
      return mask(value, ['AA_9999_99999']);
    case 'cpf':
      return mask(value, ['999.999.999-99']);
    case 'cellphone':
      return mask(value, ['+55 (99) 99999-9999']);
    case 'cep':
      return mask(value, ['99999-999']);
    default:
      console.log('Unknown');
  }
}
