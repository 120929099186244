import { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { useNavigate } from 'react-router-dom';

// STYLES
import {
  ButtonLogin,
  ButtonResend,
  ContainerButtonResend,
  ContainerLogin,
  ContentForm,
  ContentLogo,
  ResendCodeButton,
  TitleScreen,
  VerticalDivider,
} from './LoginStyle';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../../globalStyles/assets/imgs/logo-sorte-saude.svg';

// LIBS
import { handleChangeMask } from '../../utils/masks';
import { toast } from 'react-toastify';

// APIS
import { validateSMS } from '../../services/Apis/validateSMS';

export function Login() {
  const { uId } = JSON.parse(
    sessionStorage.getItem('user') !== null && sessionStorage.getItem('user')
  );
  const { user, login, resendCode, loadingResendCode } = useUser();
  const isLogged = JSON.parse(sessionStorage.getItem('redirect'));
  const transactionId = JSON.parse(sessionStorage.getItem('transactionId'));
  const navigate = useNavigate();

  const [cpf, setCpf] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendButtonActive, setResendButtonActive] = useState(false);
  const [seconds, setSeconds] = useState(60);

  // SE O USUARIO ESTIVER LOGADO NÃO POSSUI ACESSO A ESTA PÁGINA;
  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [isLogged]);

  // CONTADOR DE TEMPO PARA REENVIO DE CÓDIGO
  useEffect(() => {
    if (user) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [seconds, user]);

  const handleCellphone = (e) => {
    const cpfMask = handleChangeMask('cpf', e.target.value);
    setCpf(cpfMask);
  };

  const verifyUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const cpfTreatmentForApi = cpf.replace(/[-.]/g, '');
    const phoneUser = await login(cpfTreatmentForApi);
    setCellphone(phoneUser);
    setLoading(false);
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const resultValidate = await validateSMS(uId, code, cellphone, transactionId);

      if (resultValidate.success) {
        // LINK QUE VAI ENVIAR PARA PÁGINAS DE ESCOLHER PDV E ESTABELECIMENTO
        sessionStorage.setItem('redirect', true);
        setLoading(false);
        navigate('/selecionar-pdv');
      } else {
        toast.error('Algo ocorreu de errado, tente novamente mais tarde!');
      }
    } catch (error) {
      if (error.includes('Invalid code!')) {
        toast.error('Código inválido!');
      } else {
        toast.error('Algo não funcionou corretamente. Tente novamente mais tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  //EXIBE OS BOTÕES DE OPÇÃO DE REENVIO DE CÓDIGO
  const seeResendCode = (e) => {
    e.preventDefault();

    setResendButtonActive(true);
  };

  // FUNCÃO QUE DEFINE SE O REENVIO DO CODIGO VAI SER VIA SMS OU WHATSAPP
  const sendType = (type) => {
    if (type === 'sms') {
      const sendType = { email: false, sms: true, whatsapp: false };

      return sendType;
    } else if (type === 'whatsapp') {
      const sendType = { email: false, sms: false, whatsapp: true };

      return sendType;
    }
  };

  const resendCodeAction = (type) => {
    resendCode(cellphone, sendType(type));

    setTimeout(() => {
      setResendButtonActive(false);
      setSeconds(60);
    }, 2500);
  };

  return (
    <ContainerLogin>
      <TitleScreen>INTERFACE DO PDV</TitleScreen>
      <ContentLogo>
        <img src={logo} alt="Logomarca sorte e saude" />
      </ContentLogo>
      <VerticalDivider />
      {!user ? (
        <ContentForm onSubmit={verifyUser}>
          <p>Digite o seu CPF.</p>
          <input placeholder="XXX.XXX.XXX-XX" onChange={handleCellphone} type="tel" value={cpf} />
          <ButtonLogin disabled={cpf.length < 14} type="submit" variant="contained">
            {loading ? <CircularProgress size={26} thickness={5} /> : 'ENTRAR'}
          </ButtonLogin>
        </ContentForm>
      ) : (
        <ContentForm onSubmit={verifyCode}>
          <p>Insira o código enviado para o número {`+55 (XX) XXXXX - ${cellphone.slice(-4)}`}.</p>
          <input
            maxLength="6"
            placeholder="XXXXXX"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <p>
            Não recebeu o código?{' '}
            {seconds === 0 ? (
              <ResendCodeButton onClick={seeResendCode}>Reenvie clicando aqui.</ResendCodeButton>
            ) : (
              'Em ' + seconds + ' você poderá solicitar um novo código.'
            )}
          </p>

          {resendButtonActive && (
            <ContainerButtonResend>
              <ButtonResend disabled={loadingResendCode} onClick={() => resendCodeAction('sms')}>
                {loadingResendCode ? <CircularProgress size={26} thickness={5} /> : 'Sms'}
              </ButtonResend>
              <ButtonResend
                disabled={loadingResendCode}
                onClick={() => resendCodeAction('whatsapp')}
              >
                {loadingResendCode ? <CircularProgress size={26} thickness={5} /> : 'Whatsapp'}
              </ButtonResend>
            </ContainerButtonResend>
          )}

          <ButtonLogin disabled={code.length < 6 || loading} type="submit" variant="contained">
            {loading ? <CircularProgress size={26} thickness={5} /> : 'VALIDAR'}
          </ButtonLogin>
        </ContentForm>
      )}
    </ContainerLogin>
  );
}
