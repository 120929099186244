import { Button } from '@mui/material';
import styled from 'styled-components';

export const ContainerModalLogout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;

  height: 10rem;
  text-align: center;
`;

export const ButtonCloseModal = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: 20px;
  transition: 0.3s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Text = styled.p`
  font-size: 1.1rem;
`;

export const ContentButtons = styled.span`
  display: flex;
  gap: 2rem;
`;

export const ButtonLogout = styled(Button)``;
