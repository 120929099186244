import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary: #1565B4;
    --secondary: #ebb61f;
    --primaryLight: #2196F3;
  
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }
  
  button {
    cursor: pointer;
  }

  [disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  //USAR POR PADRÃO O REACT MODAL.
  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.8);

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
     width: 100%;
     max-width: 380px;
     min-height: 280px;

     background: #fff;
     border-top: solid 3rem;
     border-color: var(--primary);
     border-radius: 6px;
     padding: 3rem;
     position: relative;

     @media screen and (max-width:500px){
      max-width: 320px;

     }
  }
  
  .react-modal-content-scan {
     width: 100%;
     max-width: 380px;
     min-height: 280px;

     background: transparent;
     border-radius: 10px;
     padding: 3rem;
     position: relative;

     @media screen and (max-width:500px){
      max-width: 320px;

     }
  }
  
  .react-modal-close {
    
    &:hover {
      filter: brightness(0.8);
    }
  }
`;
