import { useEffect, useState } from 'react';
import { useAccredited } from '../../hooks/useAccredited';
import { useUser } from '../../hooks/useUser';

// STYLES
import {
  ContainerSelectPdv,
  ContentNoHaveEstablishment,
  ContentSelectEstablishment,
  ExitButton,
  InputContainer,
  InputSearch,
  NextButton,
  SearchIcon,
  Title,
} from '../../pages/SelectPdv/SelectPdvStyle';
import { CircularProgress, Radio } from '@mui/material';

export const Step1 = ({ loading, next, infos }) => {
  const { logout } = useUser();
  const { accredited, setAccredited } = useAccredited();
  const [search, setSearch] = useState('');
  const [accreditedFilter, setAccreditedFilter] = useState([]);

  // REMOVE OS CREDENCIADOS DUPLICADOS.
  let uniquePdv = infos
    .filter((item, index, self) => {
      return self.findIndex((t) => t.accreditedId === item.accreditedId) === index;
    })
    .sort((a, b) => {
      if (a.accreditedName < b.accreditedName) {
        return -1;
      }
      if (a.accreditedName > b.accreditedName) {
        return 1;
      }
      return 0;
    });

  // FILTRA OS CREDENCIAMENTOS
  useEffect(() => {
    if (search.length >= 1) {
      const filteredAccredited = uniquePdv.filter((accredited) => {
        return accredited.accreditedName.toLowerCase().includes(search.toLowerCase());
      });
      setAccreditedFilter(filteredAccredited);
    }
  }, [uniquePdv, search]);

  return (
    <ContainerSelectPdv>
      <Title>Credenciado</Title>
      <InputContainer>
        <InputSearch
          name="accreditedName"
          type="text"
          placeholder="Buscar credenciamento"
          onChange={(e) => setSearch(e.target.value)}
        />
        <SearchIcon />
      </InputContainer>
      <ContentSelectEstablishment>
        {loading ? (
          <ContentNoHaveEstablishment>
            <CircularProgress size={80} thickness={5} style={{ color: '#fff' }} />
          </ContentNoHaveEstablishment>
        ) : (
          <>
            {uniquePdv.length === 0 ? (
              <ContentNoHaveEstablishment>
                Você não está associado a nenhum credenciamento.
              </ContentNoHaveEstablishment>
            ) : search.length >= 1 ? (
              accreditedFilter.map((estab) => (
                <table key={estab.accreditedId}>
                  <tbody>
                    <tr>
                      <td>
                        <Radio
                          type="radio"
                          value={estab.accreditedId}
                          checked={accredited?.accreditedId === estab.accreditedId}
                          onChange={() =>
                            setAccredited({
                              ...accredited,
                              accreditedId: estab.accreditedId,
                              accreditedName: estab.accreditedName,
                            })
                          }
                          sx={{
                            color: '#EBB61F',
                            '&.Mui-checked': {
                              color: '#EBB61F',
                            },
                          }}
                        />
                        <span>{estab.accreditedName}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
            ) : (
              uniquePdv.map((estab) => (
                <table key={estab.accreditedId}>
                  <tbody>
                    <tr>
                      <td>
                        <Radio
                          type="radio"
                          value={estab.accreditedId}
                          checked={accredited?.accreditedId === estab.accreditedId}
                          onChange={() =>
                            setAccredited({
                              ...accredited,
                              accreditedId: estab.accreditedId,
                              accreditedName: estab.accreditedName,
                            })
                          }
                          sx={{
                            color: '#EBB61F',
                            '&.Mui-checked': {
                              color: '#EBB61F',
                            },
                          }}
                        />
                        <span>{estab.accreditedName}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
            )}
          </>
        )}
      </ContentSelectEstablishment>

      {uniquePdv.length > 0 ? (
        <NextButton
          onClick={() => next('next')}
          disabled={accredited.accreditedId === '' || accredited.accreditedName === ''}
        >
          Avançar
        </NextButton>
      ) : (
        <ExitButton onClick={logout} disabled={loading}>
          Sair
        </ExitButton>
      )}
    </ContainerSelectPdv>
  );
};
