// STYLES
import { useEffect, useState } from 'react';
import { useAccredited } from '../../hooks/useAccredited';
import {
  ContainerButtons,
  ContainerSelectPdv,
  ContentNoHaveEstablishment,
  ContentSelectEstablishment,
  InputContainer,
  InputSearch,
  NextButton,
  SearchIcon,
  Title,
} from '../../pages/SelectPdv/SelectPdvStyle';
import { CircularProgress, Radio } from '@mui/material';

export const Step2 = ({ loading, infos, next }) => {
  const { accredited, pdv, setPdv } = useAccredited();
  const [search, setSearch] = useState('');
  const [pdvFilter, setPdvFilter] = useState([]);
  // VERIFICA QUAL CREDENCIADO FOI SELECIONADO E FILTRA APENAS OS ESTABELECIMENTOS DESSE CREDENCIADO.
  const pdvForAccreditedSelect = infos
    .filter((item) => item.accreditedId === accredited.accreditedId)
    .sort((a, b) => {
      if (a.establishments.nome < b.establishments.nome) {
        return -1;
      }
      if (a.establishments.nome > b.establishments.nome) {
        return 1;
      }
      return 0;
    });

  // FILTRA OS DPV
  useEffect(() => {
    if (search.length >= 1) {
      const filteredPdv = pdvForAccreditedSelect.filter((pdv) => {
        return pdv.establishments.nome.toLowerCase().includes(search.toLowerCase());
      });
      setPdvFilter(filteredPdv);
    }
  }, [pdvForAccreditedSelect, search]);

  return (
    <ContainerSelectPdv>
      <Title>Ponto de venda</Title>
      <InputContainer>
        <InputSearch
          name="pdvName"
          type="text"
          placeholder="Buscar ponto de venda"
          onChange={(e) => setSearch(e.target.value)}
        />
        <SearchIcon />
      </InputContainer>
      <ContentSelectEstablishment>
        {loading ? (
          <ContentNoHaveEstablishment>
            <CircularProgress size={80} thickness={5} style={{ color: '#fff' }} />
          </ContentNoHaveEstablishment>
        ) : (
          <>
            {pdvForAccreditedSelect.length === 0 ? (
              <ContentNoHaveEstablishment>
                Não há nenhum estabelecimento vínculado a esse credenciamento.
              </ContentNoHaveEstablishment>
            ) : search.length >= 1 ? (
              pdvFilter.map((item) => (
                <table key={item.establishments.id}>
                  <tbody>
                    <tr>
                      <td>
                        <Radio
                          type="radio"
                          value={item.establishments.id}
                          checked={pdv?.pdvId === item.establishments.id}
                          onChange={() =>
                            setPdv({
                              ...pdv,
                              pdvId: item.establishments.id,
                              pdvName: item.establishments.nome,
                            })
                          }
                          sx={{
                            color: '#EBB61F',
                            '&.Mui-checked': {
                              color: '#EBB61F',
                            },
                          }}
                        />
                        <span>{item.establishments.nome}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
            ) : (
              pdvForAccreditedSelect.map((item) => (
                <table key={item.establishments.id}>
                  <tbody>
                    <tr>
                      <td>
                        <Radio
                          type="radio"
                          value={item.establishments.id}
                          checked={pdv?.pdvId === item.establishments.id}
                          onChange={() =>
                            setPdv({
                              ...pdv,
                              pdvId: item.establishments.id,
                              pdvName: item.establishments.nome,
                            })
                          }
                          sx={{
                            color: '#EBB61F',
                            '&.Mui-checked': {
                              color: '#EBB61F',
                            },
                          }}
                        />
                        <span>{item.establishments.nome}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
            )}
          </>
        )}
      </ContentSelectEstablishment>
      <ContainerButtons>
        <NextButton onClick={() => next('prev')}>Voltar</NextButton>
        <NextButton onClick={next} disabled={pdv.pdvId === '' || pdv.pdvName === ''}>
          Avançar
        </NextButton>
      </ContainerButtons>
    </ContainerSelectPdv>
  );
};

